import * as React from "react"

import PickerScreen from '../../../../../components/pickerScreen'


const Picker = ({ data }) => (
  <PickerScreen
    question="How advanced?"
    options={[
      {
        label: "All the vector design classics",
        link: "/app/digital-product-design/hi-fi/how-do-you-want-to-work/"
      },
      {
        label: "Complex animations and interactions",
        link: "/app/digital-product-design/hi-fi/complex-animations-and-interactions/"
      }
  ]}/>
)

export default Picker;


